<template>
  <div id="page-solution">
    <Navigation/>
    <div class="solution-container">
      <div>
        <div class="solution-intro">
          <div class="title animate__animated animate__fadeInLeft">통합 문서 관리 프로세스 구축</div>
          <div class="subtitle animate__animated animate__fadeInLeft animate__delay-1s">문서 제작, 관리, 재사용, 번역 및 멀티채널 배포가 그 어느 때보다 쉬워졌습니다!</div>
          <div class="desc animate__animated animate__fadeInLeft animate__delay-1s">고품질 문서를 생성하기 위한 가장 효율적인 시스템을 구축합니다.<br>TEXTree 문서관리 시스템을 사용하면 포괄적이고 사용자 친화적인 인터페이스를 사용하여 여러 버전의 다국어 문서를 만들고 관리할 수 있습니다.</div>
        </div>
        <div class="solutions">
          <div class="caption animate__animated animate__fadeInUp animate__delay-2s">Our Solution Services</div>
          <ul>
            <li v-for="solution in solutions" :key="solution.id" :class="solution.id == selectedSolution.id ? 'selected animate__animated animate__fadeInUp animate__delay-2s':'' + 'animate__animated animate__fadeInUp animate__delay-2s'">
              <a href="javascript:void(0)" @click="previewSolution(solution.id)">{{solution.name}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="cover animate__animated animate__fadeInRight" :style="'background-image: url(' + selectedSolution.cover + ');'"></div>
        <div class="pane animate__animated animate__fadeInDown animate__delay-2s">
          <div>
            <div class="pane-nav">
              <a href="javascript:void(0)" class="prev" @click.stop="prevSolution"></a>
              <a href="javascript:void(0)" class="next" @click.stop="nextSolution"></a>
            </div>
            <div class="pane-title">{{selectedSolution.pane_title}}</div>
            <div class="pane-content-title">{{selectedSolution.content_title}}</div>
            <div class="pane-icons">
              <div v-for="icon in selectedSolution.solution_icons" :key="icon" class="icon" :style="'background-image: url(' + icon + ');'"></div>
            </div>
            <div class="pane-summaries">
              <ul>
                <li v-for="summary in selectedSolution.summaries" :key="summary">{{summary}}</li>
              </ul>
            </div>
            <div class="pane-link">
              <a :href="'/solution/' + selectedSolution.url">자세히 보기</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BgTextree/>
  </div>
</template>

<script>
import store from '@/store'

export default {
  components: {
    Navigation: () => import('@/components/nav.vue'),
    BgTextree: () => import('@/components/bg-textree.vue')
  },
  data: () => ({
    selectedSolution: {}
  }),
  computed: {
    solutions: () => store.state.solution.solutions
  },
  mounted() {
    store.commit('SET_NAV_PAGE', 'solution')
    store.commit('SET_NAV_THEME', 'dark')
    this.previewSolution(this.solutions[0].id)
  },
  methods: {
    previewSolution: function (id) {
      for (let solution of this.solutions) {
        if (solution.id == id) {
          this.selectedSolution = solution;
        }
      }
    },
    prevSolution: function () {
      var ctr = 0;
      for (let solution of this.solutions) {
        if (solution.id == this.selectedSolution.id) {
          var sol = {};
          if (ctr > 0) {
            sol = this.solutions[ctr-1];
          } else {
            sol = this.solutions[this.solutions.length - 1];
          }
          this.selectedSolution = sol;
          break;
        }
        ctr++;
      }
    },
    nextSolution: function () {
      var ctr = 0;
      for (let solution of this.solutions) {
        if (solution.id == this.selectedSolution.id) {
          var sol = {};
          if (ctr < this.solutions.length - 1) {
            sol = this.solutions[ctr+1];
          } else {
            sol = this.solutions[0];
          }
          this.selectedSolution = sol;
          break;
        }
        ctr++;
      }
    }
  }
}
</script>
<style scoped>
div#page-solution {
  position: absolute;
  width: 100%;
  top: 66px;
  height: calc(100% - 66px);
  background: white;
  overflow-y: scroll;
}

div.solution-container {
  max-width: calc(1600px - 10%);
  width: calc(100% - 48px);
  height: calc(100% - 100px);
  margin: auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 24px;
}
div.solution-container > div {
  flex: 0 0 50%;
}
div.solution-container > div:last-of-type {
  flex: 0 0 45%;
}

div.title {
  font-family: "Roboto";
  font-size: 45px;
  font-weight: bold;
  line-height: 59px;
  color: #008ED6;
  margin-bottom: 24px;
}
div.subtitle {
  font-family: "Nanum Gothic";
  font-size: 25px;
  font-weight: bold;
  line-height: 45px;
  margin-bottom: 16px;
}
div.desc {
  font-family: "Nanum Gothic";
  font-size: 18px;
  font-weight: bold;
  line-height: 45px;
  color: #959595;
  margin-bottom: 30px;
}

div.caption {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: bold;
  line-height: 70px;
  color: #008ED6;
}
div.solutions ul {
  list-style-type: none;
  padding-left: 0;
}
div.solutions ul li {
  font-family: "Roboto";
  font-size: 35px;
  font-weight: normal;
  line-height: 80px;
}
div.solutions li a {
  color: #CFCFCF;
  text-decoration: none;
}
div.solutions li.selected a {
  color: #2E2E2E;
}

div.cover {
  position: absolute;
  width: calc(50% + 48px);
  top: 0;
  left: calc(50% + 48px + 160px);
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
div.pane {
  margin-top: 64px;
  background: #F1F1F1;
  border-radius: 32px;
  padding: 16px;
}
div.pane > div {
  background: #FFFFFF;
  border-radius: 24px;
  padding: 12px;
  text-align: center;
  position: relative;
}

div.pane-title {
  font-family: "Nanum Gothic";
  font-size: 30px;
  font-weight: bold;
  line-height: 42px;
}
div.pane-content-title {
  display: inline-block;
  padding: 12px;
  margin: 16px auto;
  background: #008ED6;
  border-radius: 32px;
  font-family: "Nanum Gothic";
  font-size: 16px;
  color: white;
}

div.pane-icons > div.icon {
  width: 32%;
  margin: 1px auto;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

div.pane-summaries {
  width: 72%;
  margin: 24px auto;
  font-family: "Nanum Gothic";
  font-size: 18px;
  line-height: 40px;
  text-align: left;
}

div.pane-link {
  display: inline-block;
  margin: 12px auto;
}
div.pane-link a {
  font-family: "Nanum Gothic";
  font-size: 18px;
  padding: 12px 24px;
  background: #F1B500;
  border-radius: 31px;
  text-decoration: none;
  color: white;
}

div.pane-nav a {
  display: none;
  position: absolute;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}
div.pane-nav a.prev {
  left: 5px;
  background-image: url('/assets/icons/circle-arrow-left.png');
}
div.pane-nav a.next {
  right: 5px;
  background-image: url('/assets/icons/circle-arrow-right.png');
}

@media(max-width: 1280px) {
  div.solution-container > div {
    flex-basis: calc(55% - 24px);
  }
  div.solutions ul li {
    font-size: 32px;
    line-height: 60px;
  }
}

@media(max-width: 1024px) {
  div.cover {
    left: calc(50% + 128px);
  }
  div.solutions ul li {
    font-size: 24px;
    line-height: 36px;
  }
  div.desc {
    font-size: 16px;
    line-height: 36px;
  }
  div.title {
    font-size: 36px;
  }
  div.subtitle {
    font-size: 20px;
    line-height: 36px;
  }
  div.pane-title {
    font-size: 24px;
  }
  div.pane-summaries {
    width: 100%;
    line-height: 36px;
  }
  div.pane-icons > div.icon {
    width: 50%;
    height: 64px;
  }
}

@media(max-width: 768px) {
  div.title {
    font-size: 30px;
    line-height: 36px;
  }
  div.subtitle {
    line-height: 30px;
  }
  div.desc {
    line-height: 24px;
  }
  div.pane-summaries {
    font-size: 16px;
    line-height: 24px;
  }
  div.cover {
    left: calc(50% + 64px);
  }
  div.solutions ul li {
    font-size: 20px;
    line-height: 30px;
  }
  div.solution-container > div {
    flex-basis: calc(50% - 24px);
  }
  div.solution-container > div:last-of-type {
    flex-basis: 50%;
  }
}

@media(max-width: 576px) {
  div.solution-container {
    flex-direction: column;
  }
  div.solution-container > div {
    flex-basis: auto;
  }
  div.solution-container > div:last-of-type {
    position: relative;
    margin-left: -12px;
    margin-right: -12px;
  }
  div.title {
    font-size: 24px;
  }
  div.subtitle {
    font-size: 18px;
    line-height: 22px;
  }
  div.desc {
    line-height: 20px;
  }
  div.solutions {
    position: absolute;
    margin-top: 32px;
    margin-left: -12px;
    width: 40%;
    z-index: 99;
  }
  div.solutions ul li {
    line-height: 24px;
    margin-bottom: 24px;
  }
  div.caption {
    font-size: 18px;
    line-height: 24px;
  }
  div.cover {
    left: calc(40% + 24px);
  }
  div.pane {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 50%;
  }
  div.pane-title {
    font-size: 12px;
    line-height: 16px;
  }
  div.pane-content-title {
    font-size: 9px;
  }
  div.pane-summaries {
    font-size: 9px;
    line-height: 16px;
    margin: 12px auto;
  }
  div.pane-summaries ul {
    padding-left: 12px;
  }
  div.pane-icons > div.icon {
    width: 100%;
    height: 36px;
  }
  div.pane-link a {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media(max-width: 576px) {

  div.title {
    font-size: 25px;
  }

  div.subtitle {
    font-size: 18px;
    line-height: 30px;
  }

  div.desc {
    line-height: 25px;
    font-size: 12px;
  }

  div.solutions ul li {
    line-height: 24px;
    font-size: 15px;
    margin-bottom: 24px;
  }

  div.pane {
    margin-top: 0;
  }
  div.pane-link {
    margin: 0 auto;
  }
}

@media(max-width: 425px) {
  div.title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  div.subtitle {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  div.desc {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
  }
}

@media(max-height: 680px) {
  div.caption {
    font-size: 16px;
  }
  div.cover {
    display: none;
  }
  div.solution-container {
    padding: 0;
  }
  div.title,
  div.subtitle {
    font-size: 12px;
    margin-bottom: 6px;
  }
  div.solutions ul li {
    font-size: 12px;
    margin-bottom: 12px;
  }
  div.pane {
    padding: 8px;
    margin-bottom: 0;
  }
}
</style>

<style>
#plusfriend-chat-button {
  bottom: 64px;
}
#plusfriend-chat-button a {
  right: -128px;
}

@media(max-width: 1440px) {
  #plusfriend-chat-button a {
    right: 12px;
  }
}

@media(max-width: 576px) {
  #plusfriend-chat-button {
    bottom: 12px;
  }
}
</style>
